import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import useDarkMode from 'use-dark-mode';
import { ReactComponent as Moon } from './moon.svg';
import { ReactComponent as Sun } from './sun.svg';

const DarkMode: FC = () => {
  const { t } = useTranslation();
  const initialState =
    localStorage.theme === 'dark' ||
    (!('theme' in localStorage) &&
      window.matchMedia('(prefers-color-scheme: dark)').matches);
  const { toggle, value: darkMode } = useDarkMode(initialState, {
    classNameDark: 'dark',
    classNameLight: 'light',
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    element: document.querySelector('html')!,
    storageKey: 'theme',
  });

  const svgStyle = { height: 25, width: 25 };

  return (
    <>
      <button onClick={toggle}>
        {darkMode ? (
          <Sun title={t('themes.light')} style={svgStyle} />
        ) : (
          <Moon title={t('themes.dark')} style={svgStyle} />
        )}
      </button>
    </>
  );
};

export default DarkMode;
