import { FC } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const NotFound: FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>
          {`${t('error.notFound')} - ${process.env.REACT_APP_TITLE}`}
        </title>
      </Helmet>
      <div className="container center">
        <h1>{t('error.notFound')}</h1>
        <Link to="/">{t('error.toHomePage')}</Link>
      </div>
    </>
  );
};

export default NotFound;
