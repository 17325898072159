import classNames from 'classnames';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { supportedLngs } from '../../../i18n';
import DarkMode from '../../atoms/DarkMode';
import styles from './Footer.module.scss';

const Footer: FC = () => {
  const { t } = useTranslation();
  const { search } = useLocation();
  const prefix = useMemo(() => {
    if (!search) {
      return '?';
    }

    const queryParams = new URLSearchParams(search);

    queryParams.delete('lng');
    return `?${queryParams.toString()}&`;
  }, [search]);

  return (
    <footer className={classNames(styles.footer, 'component-bg')}>
      <div className="container flex flex-col md:flex-row md:justify-between">
        <section className="flex-1">
          <h4>{t('footer.languages')}</h4>
          <ul>
            {supportedLngs.map((locale) => (
              <li key={locale}>
                <a href={`${prefix}lng=${locale}`}>{t(`locales.${locale}`)}</a>
              </li>
            ))}
          </ul>
        </section>
        <section className="center">
          <DarkMode />
        </section>
        <section className="flex-1 text-left md:text-right">
          <h4>&copy; 2020 {t('footer.company')}</h4>
          <ul>
            <li>
              <Link to="/privacy-policy">{t('privacyPolicy.title')}</Link>
            </li>
            <li>
              <Link to="/contact">{t('contact.title')}</Link>
            </li>
          </ul>
        </section>
      </div>
    </footer>
  );
};

export default Footer;
