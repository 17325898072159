import { FC } from 'react';
import { Helmet } from 'react-helmet-async';
import { Trans, useTranslation } from 'react-i18next';

const PrivacyPolicy: FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>
          {`${t('privacyPolicy.title')} - ${process.env.REACT_APP_TITLE}`}
        </title>
      </Helmet>
      <div className="container center">
        <main className="main-box text-justify whitespace-pre-line">
          <h1 className="text-center">{t('privacyPolicy.title')}</h1>

          <section>
            <p>{t('privacyPolicy.introText')}</p>
          </section>

          <h2 className="mt-6">{t('privacyPolicy.infoHeader')}</h2>
          <section>
            <p>{t('privacyPolicy.infoText')}</p>
            <ul className="list-disc pl-10">
              <li>
                <a href="https://www.google.com/policies/privacy/">
                  {t('privacyPolicy.googlePlayServices')}
                </a>
              </li>
              <li>
                <a href="https://firebase.google.com/policies/analytics">
                  {t('privacyPolicy.googleAnalytics')}
                </a>
              </li>
              <li>
                <a href="https://firebase.google.com/support/privacy/">
                  {t('privacyPolicy.firebaseCrashlytics')}
                </a>
              </li>
            </ul>
          </section>

          <h2 className="mt-6">{t('privacyPolicy.gpsHeader')}</h2>
          <section>
            <p>{t('privacyPolicy.gpsText')}</p>
          </section>

          <h2 className="mt-6">{t('privacyPolicy.logHeader')}</h2>
          <section>
            <p>{t('privacyPolicy.logText')}</p>
          </section>

          <h2 className="mt-6">{t('privacyPolicy.cookiesHeader')}</h2>
          <section>
            <p>{t('privacyPolicy.cookiesText')}</p>
          </section>

          <h2 className="mt-6">{t('privacyPolicy.serviceProvidersHeader')}</h2>
          <section>
            <p>{t('privacyPolicy.serviceProvidersText1')}</p>
            <ul className="list-disc pl-10">
              <li>{t('privacyPolicy.serviceProvidersList1')}</li>
              <li>{t('privacyPolicy.serviceProvidersList2')}</li>
              <li>{t('privacyPolicy.serviceProvidersList3')}</li>
              <li>{t('privacyPolicy.serviceProvidersList4')}</li>
            </ul>
            <p>{t('privacyPolicy.serviceProvidersText2')}</p>
          </section>

          <h2 className="mt-6">{t('privacyPolicy.securityHeader')}</h2>
          <section>
            <p>{t('privacyPolicy.securityText')}</p>
          </section>

          <h2 className="mt-6">{t('privacyPolicy.linksHeader')}</h2>
          <section>
            <p>{t('privacyPolicy.linksText')}</p>
          </section>

          <h2 className="mt-6">{t('privacyPolicy.childrenHeader')}</h2>
          <section>
            <p>{t('privacyPolicy.childrenText')}</p>
          </section>

          <h2 className="mt-6">{t('privacyPolicy.changesHeader')}</h2>
          <section>
            <p>{t('privacyPolicy.changesText')}</p>
          </section>

          <h2 className="mt-6">{t('privacyPolicy.contactHeader')}</h2>
          <section>
            <p>
              <Trans
                i18nKey="privacyPolicy.contactText"
                components={{ anchor: <a href="mailto:aifa@aifa.com.tw" /> }}
              />
            </p>
          </section>
        </main>
      </div>
    </>
  );
};

export default PrivacyPolicy;
