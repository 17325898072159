import { FC, useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import useFetch from 'use-http';
import Alert from '../../atoms/Alert';
import Input from '../../atoms/Input';

interface Props {
  token: string;
}

interface FormInputs {
  password: string;
}

const ResetPasswordForm: FC<Props> = ({ token }) => {
  const { t } = useTranslation();
  const { register, handleSubmit } = useForm<FormInputs>();
  const { put, response, loading, error } = useFetch();
  const [success, setSuccess] = useState(false);
  const errorMessage = useMemo(
    () => t('errors.unknown', { code: response.status || 1 }),
    [response, t]
  );

  const resetPassword: SubmitHandler<FormInputs> = async (data) => {
    await put('/password', {
      new: data.password,
      token,
    });

    setSuccess(response.ok);
  };

  return (
    <form className="flex flex-col p-5" onSubmit={handleSubmit(resetPassword)}>
      <Input
        {...register('password')}
        id="password"
        label={t('forms.newPassword')}
        type="password"
        required
        autofocus
      />
      <button className="btn btn-primary my-4" disabled={loading || success}>
        {t('actions.reset')}
      </button>
      {success && <Alert variant="success">{t('resetPassword.success')}</Alert>}
      {error && <Alert variant="danger">{errorMessage}</Alert>}
    </form>
  );
};

export type { Props as ResetPasswordFormProps };
export default ResetPasswordForm;
