import { FC, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import useFetch from 'use-http';
import Alert from '../../components/atoms/Alert';
import ResetPasswordForm from '../../components/molecules/ResetPasswordForm';

const ResetPassword: FC = () => {
  const { t } = useTranslation();
  const { search } = useLocation();
  const { error, post, response } = useFetch();
  const [token, setToken] = useState<string>();
  const [isTokenValid, setTokenValidity] = useState<boolean>();
  const [errorMessage, setErrorMessage] = useState<string>();
  const [isReady, setReady] = useState(false);

  useEffect(() => {
    let isMounted = true;
    const queryParams = new URLSearchParams(search);

    if (!queryParams.has('token')) {
      setReady(true);
      return;
    }

    setToken(queryParams.get('token') as string);

    const validateToken = async () => {
      await post('/reset-token/verify', {
        token: queryParams.get('token') as string,
      });

      if (isMounted) {
        if (response.ok) {
          setTokenValidity(true);
        } else {
          switch (response.status) {
            case 404:
              setErrorMessage(t('errors.tokenNotFound'));
              break;

            default:
              setErrorMessage(
                t('errors.unknown', { code: response.status || 1 })
              );
              break;
          }
        }
        setReady(true);
      }
    };

    validateToken();

    return () => {
      isMounted = false;
    };
  }, [post, response, search, t]);

  useEffect(() => {
    if (!token) {
      setErrorMessage(t('errors.noToken'));
    }
  }, [t, token]);

  return (
    <>
      <Helmet>
        <title>
          {`${t('resetPassword.title')} - ${process.env.REACT_APP_TITLE}`}
        </title>
      </Helmet>

      <div className="container center">
        <div className="main-box main-box-small">
          <h1 className="text-center">{t('resetPassword.title')}</h1>
          {isReady && (
            <>
              {isTokenValid && token && (
                <ResetPasswordForm v-if="isTokenValid" token={token} />
              )}
              {(token == null || error) && (
                <Alert variant="danger">{errorMessage}</Alert>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
