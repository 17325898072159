import { FC } from 'react';
import { Route, Switch } from 'react-router-dom';
import Footer from './components/organisms/Footer';
import Authorize from './pages/Authorize';
import Contact from './pages/Contact';
import ForgotPassword from './pages/ForgotPassword';
import Home from './pages/Home';
import NotFound from './pages/NotFound';
import PrivacyPolicy from './pages/PrivacyPolicy';
import ResetPassword from './pages/ResetPassword';
import VerifyEmail from './pages/VerifyEmail';

const App: FC = () => (
  <div className="flex flex-col min-h-screen">
    <main className="flex flex-grow">
      <Switch>
        <Route exact path={'/'} component={Home} />
        <Route exact path={'/authorize'} component={Authorize} />
        <Route exact path={'/contact'} component={Contact} />
        <Route exact path={'/forgot-password'} component={ForgotPassword} />
        <Route exact path={'/privacy-policy'} component={PrivacyPolicy} />
        <Route exact path={'/reset-password'} component={ResetPassword} />
        <Route exact path={'/verify-email'} component={VerifyEmail} />
        <Route path="*" component={NotFound} />
      </Switch>
    </main>
    <Footer />
  </div>
);
export default App;
