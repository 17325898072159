import { FC, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import useFetch from 'use-http';
import Alert from '../../atoms/Alert';
import Input from '../../atoms/Input';

interface Props {
  clientId: string;
  redirectUri: string;
  state?: string;
}

interface FormInputs {
  email: string;
  password: string;
}

const AuthorizeForm: FC<Props> = ({ clientId, redirectUri, state }) => {
  const { t } = useTranslation();
  const { register, handleSubmit } = useForm<FormInputs>();
  const { post, response, loading, error } = useFetch();
  const [success, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();

  const authorize: SubmitHandler<FormInputs> = async (data) => {
    const body = await post('/oauth2/auth', {
      ...data,
      client_id: clientId,
      redirect_uri: redirectUri,
    });

    setSuccess(response.ok);

    if (response.ok) {
      window.location.href = state
        ? `${redirectUri}?code=${body.code}&state=${state}`
        : `${redirectUri}?code=${body.code}`;
    } else {
      switch (response.status) {
        case 401:
          setErrorMessage(t('errors.invalidCredentials'));
          break;

        default:
          setErrorMessage(t('errors.unknown', { code: response.status || 1 }));
          break;
      }
    }
  };

  return (
    <form className="flex flex-col p-5" onSubmit={handleSubmit(authorize)}>
      <Input
        {...register('email')}
        id="email"
        label={t('forms.email')}
        type="email"
        required
        autofocus
      />
      <Input
        {...register('password')}
        id="password"
        label={t('forms.password')}
        type="password"
        required
      />
      <button className="btn btn-primary my-4" disabled={loading} type="submit">
        {t('actions.submit')}
      </button>
      {(success || error) && (
        <div className="mb-3">
          {success && <Alert variant="success" />}
          {error && <Alert variant="danger">{errorMessage}</Alert>}
        </div>
      )}
      <div className="text-center">
        <Link to="/forgot-password">{t('authorize.forgotPassword')}</Link>
      </div>
    </form>
  );
};

export type { Props as AuthorizeFormProps };
export default AuthorizeForm;
